import React, {useRef, useState} from "react";

import api from "../../utils/api";

import {useFormik} from "formik";
import {Textarea} from "@mantine/core";

import {useCookies} from "react-cookie";

import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Promo from "../../components/Promo";
import SuccessMessage from "../../components/SuccessMessage";
import ErrorMessage from "../../components/ErrorMessage";

const Feedback = (props) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	const labelClassName = "text-xs";

	const formRef = useRef();

	const validate = (values) => {
		const errors = {};
		if (!values.message) {
			errors.message = "Te pole sõnumit sisestanud";
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			message: "",
		},
		validate: validate,
		validateOnChange: false,
		onSubmit: (values, {setValues}) => {
			api
				.sendMessage(token, {id: idUser, message: values.message})
				.then(() => {
					formik.resetForm();
					formRef.current.reset();
					setSuccess(true);
				})
				.catch((err) => {
					setError(true);
					console.log(err);
				});
		},
	});

	function handleChange(e) {
		formik.handleChange(e);
		setSuccess(false);
	}

	return (
		<Layout location={props.location}>
			<Section
				title={
					"Kas Sul on tekkinud küsimusi dieedi läbimise käigus? Võta meiega ühendust!"
				}
			>
				<div className={"w-full max-w-sm mt-6 md:mt-3"}>
					<h2 className={"text-center text-md sm:text-lg font-medium"}>
						Palun jäta teade oma isiklikule konsultandile:
					</h2>
					{success ? <SuccessMessage message={"Teie sõnum on saadetud"}/> : ""}
					{formik.errors.message ? (
						<ErrorMessage error={formik.errors.message}/>
					) : (
						""
					)}
					{error ? <ErrorMessage error={"Sõnumit ei saadetud"}/> : ""}
					<form
						ref={formRef}
						className={"w-full flex flex-col"}
						onSubmit={formik.handleSubmit}
					>
						<div>
							<label htmlFor={"message"} className={labelClassName}>
								Sõnumi sisu: <span className={"text-red"}>*</span>
							</label>
							<Textarea
								id={"message"}
								required="true"
								onChange={handleChange}
								value={formik.values.message}
								name={"message"}
								type={"text"}
								styles={{
									wrapper: {marginTop: "14px"},
									root: {margin: "0"},
									input: {height: "280px"},
								}}
							></Textarea>
						</div>
						<button
							type={"submit"}
							className={
								"bg-green text-white w-full max-w-xs py-3 rounded-md mt-3 text-sm self-center mt-10"
							}
						>
							Saada sõnum
						</button>
					</form>
				</div>
			</Section>
			<Promo/>
		</Layout>
	);
};

export default Feedback;
